'use client';

import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { DestinationsMenu } from '../../../resources/destinationsMenu/domain/types/destinationsMenu';
import { HubDestinationMenu } from '../../../resources/hubDestinationMenu/domain/types/hubDestinationMenu';
import { useBreakPoint } from '../BreakPoint/BreakPoint';

import style from './NewPrimaryNav.module.css';
import { ResponsiveMenu } from './ResponsiveMenu';

import { PrimaryNavContextContinent, PrimaryNavValue, PrimaryNavWhereToGoMenu } from './types';

type Props = { previewMode?: boolean } & (
    | {
          noMenu: true;
          contextContinent?: never;
          hubDestinationMenu?: never;
          destinationsMenu?: never;
          whereToGoMenu?: never;
      }
    | {
          noMenu?: never;
          contextContinent: PrimaryNavContextContinent | null;
          hubDestinationMenu: HubDestinationMenu | null;
          destinationsMenu: DestinationsMenu | null;
          whereToGoMenu: PrimaryNavWhereToGoMenu | null;
      }
);

export function NewPrimaryNav({
    contextContinent,
    hubDestinationMenu,
    destinationsMenu,
    whereToGoMenu,
    noMenu,
    previewMode,
}: Props) {
    const { isXs, isSm, isMd, isLg } = useBreakPoint();
    const [value, setValue] = useState<PrimaryNavValue>(null);
    const isMobileBreakPoint = isXs() || isSm() || isMd() || isLg();
    const lastScrollYRef = useRef(0);
    const headerRef = useRef<HTMLElement>(null);
    const [isScrollingDown, setIsScrollingDown] = useState(false);

    useEffect(() => {
        function handleScroll() {
            if (!headerRef.current) {
                return null;
            }

            const headerHeight = headerRef.current.clientHeight + 50;
            const currentScrollY = window.scrollY < 0 ? 0 : window.scrollY;
            const isScrollingDown =
                currentScrollY > headerHeight && currentScrollY > lastScrollYRef.current;

            setIsScrollingDown(isScrollingDown);

            lastScrollYRef.current = currentScrollY;
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (value && isMobileBreakPoint) {
            document.body.classList.add('no-scroll');
        }
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [value, isMobileBreakPoint]);

    return (
        <header
            ref={headerRef}
            className={cx(style.primaryNav, {
                [style.isOpen]: value !== null && value !== 'USER_ACCOUNT',
                [style.previewMode]: previewMode,
                [style.hidden]: isScrollingDown,
            })}
        >
            {noMenu ? (
                <ResponsiveMenu
                    noMenu={noMenu}
                    value={value}
                    setValue={setValue}
                    previewMode={previewMode}
                />
            ) : (
                <ResponsiveMenu
                    contextContinent={contextContinent}
                    hubDestinationMenu={hubDestinationMenu}
                    destinationsMenu={destinationsMenu}
                    whereToGoMenu={whereToGoMenu}
                    value={value}
                    setValue={setValue}
                    previewMode={previewMode}
                />
            )}
        </header>
    );
}
