import { Dispatch, SetStateAction } from 'react';

import { useIntl } from 'react-intl';

import { useTrackEventAmplitude } from '../../../../../../core/Tracking/Amplitude/Amplitude';
import { Button } from '../../../../../../design-system/components/form/button/Button';
import { NavMenuImageCard } from '../../../../../../design-system/components/nav/nav-menu-image-card/NavMenuImageCard';
import { NavMenuLink } from '../../../../../../design-system/components/nav/nav-menu-link/NavMenuLink';
import { RATIO } from '../../../../../../design-system/components/ratio/Ratio';
import { Typography } from '../../../../../../design-system/components/typography/Typography';
import { NormalizedWhereToGoMenu } from '../../../../../../resources/whereToGoMenu/api/prismic/normalizers';
import { useUrlGenerator } from '../../../../../../shared/hooks/useUrlGenerator';
import { PrimaryNavValue, WhereToGoThematicValue } from '../../../types';
import { MobileSubNav } from '../MobileSubNav/MobileSubNav';

import style from './WhatView.module.css';

export function WhatView({
    what,
    onNavValueChange,
    setCurrentThematicCategory,
}: {
    what: NonNullable<NonNullable<NormalizedWhereToGoMenu>['what']>;
    onNavValueChange: (value: PrimaryNavValue) => void;
    setCurrentThematicCategory: Dispatch<SetStateAction<WhereToGoThematicValue>>;
}) {
    const { generatePath } = useUrlGenerator();
    const { formatMessage } = useIntl();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <div>
            <MobileSubNav
                title={what.title}
                showPreviousButton={true}
                onPrevious={() => {
                    onNavValueChange('WHERE_TO_GO');

                    setCurrentThematicCategory(null);

                    trackEvent((ampli, defaultProperties) => {
                        ampli.primaryNavPreviousStepClicked({
                            ...defaultProperties,
                            block: 'primary_nav',
                        });
                    });
                }}
            />

            <div className={style.wrapper}>
                <div className={style.grid}>
                    {what.thematicsHighlight.thematics.slice(0, 5).map((thematic, index) => (
                        <NavMenuImageCard
                            key={thematic.hubpage.id}
                            label={thematic.label}
                            blur={true}
                            obfuscate={true}
                            aspectRatio={RATIO['1/1']}
                            href={generatePath('hub_thematic', {
                                thematicSlug: thematic.hubpage.slug,
                            })}
                            image={{
                                alt: thematic.hubpage.coverPicture.card_cover_mobile.alt,
                                src: thematic.hubpage.coverPicture.card_cover_mobile.url,
                                loading: 'lazy',
                                unoptimized: true,
                            }}
                            onClick={() => {
                                trackEvent((ampli, defaultProperties) => {
                                    ampli.primaryNavThematicClicked({
                                        ...defaultProperties,
                                        block: 'primary_nav',
                                        card_number: index + 1,
                                        row_number: 3,
                                        thematic_slug: thematic.hubpage.slug,
                                        click_type: 'card',
                                        column_number: 0,
                                        thematic_group: 'what',
                                    });
                                });
                            }}
                        />
                    ))}
                </div>

                <div className={style.columnsSection}>
                    {what.thematicsColumns.map((column, index) => {
                        return (
                            <div className={style.column} key={index + 1}>
                                {column.title ? (
                                    <Typography className={style.title} scale="primary-xs-bold">
                                        {column.title}
                                    </Typography>
                                ) : null}

                                <div className={style.columnItemsWrapper}>
                                    {column.thematics.slice(0, 7).map((thematic, itemsIndex) => (
                                        <NavMenuLink
                                            key={thematic.hubpage.id}
                                            className={style.columnItem}
                                            href={generatePath('hub_thematic', {
                                                thematicSlug: thematic.hubpage.slug,
                                            })}
                                            onClick={() => {
                                                trackEvent((ampli, defaultProperties) => {
                                                    ampli.primaryNavThematicClicked({
                                                        ...defaultProperties,
                                                        block: 'primary_nav',
                                                        card_number: itemsIndex + 1,
                                                        column_number: index + 1,
                                                        row_number: 3,
                                                        thematic_slug: thematic.hubpage.slug,
                                                        click_type: 'link',
                                                        thematic_group: 'what',
                                                    });
                                                });
                                            }}
                                        >
                                            {thematic.label}
                                        </NavMenuLink>
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className={style.footer}>
                <Button
                    priority="secondary"
                    reversed
                    href={generatePath('where_to_go')}
                    onClick={() => {
                        trackEvent((ampli, defaultProperties) => {
                            ampli.primaryNavWheretogoClicked({
                                ...defaultProperties,
                                block: 'primary_nav',
                            });
                        });
                    }}
                >
                    {formatMessage({
                        id: 'global.header.globalNav.new.whereToGo.cta',
                    })}
                </Button>
            </div>
        </div>
    );
}
